import { List } from '@mui/icons-material';
import { Box, CircularProgress, Fade, ListSubheader, MenuItem, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from '../../contexts/MainContext';
import {
  SET_KNOWN_AGENT_TYPES,
  SET_SELECTED_LIST,
  usePrimusDispatch,
  usePrimusState
} from "../../contexts/PrimusContext";
import { RESET_TABLE_STATE, useTableDispatch } from "../../contexts/TableContext";
import { SolrSearchParams } from "../../declarations/SolrSearchParameters";
import { PrimusApi } from '../../services/PrimusApi';

import { ListSelectorItems } from '../../utils/primusListSelectorHandler';

export const ListSelector = () => {
  const mainDispatch = useMainDispatch();
  const primusDispatch = usePrimusDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const primusDispatchCallback = useCallback(primusDispatch, [primusDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const [availableLists, setAvailableLists] = useState({});
  const [loading, setLoading] = useState(true);

  const { notifications } = useMainState();
  const { baseUrl, selectedList } = usePrimusState();
  const { t } = useTranslation('translations');

  const isKnownAgentType = (typeId) => {
    for (const item of availableLists.name?.items || []) {
      if (item.id.startsWith('(')) {
        let ids = item.id.substring(1, item.id.length - 1).split(' ');
        for (const id of ids) {
          if (id === typeId) {
            return true;
          }
        }
      } else if (item.id === typeId) {
        return true;
      }
    }

    for (const item of availableLists.namedObjects?.items || []) {
      if (item.id.startsWith('(')) {
        let ids = item.id.substring(1, item.id.length - 1).split(' ');
        for (const id of ids) {
          if (id === typeId) {
            return true;
          }
        }
      } else if (item.id === typeId) {
        return true;
      }
    }

    for (const item of availableLists.properName?.items || []) {
      if (item.id.startsWith('(')) {
        let ids = item.id.substring(1, item.id.length - 1).split(' ');
        for (const id of ids) {
          if (id === typeId) {
            return true;
          }
        }
      } else if (item.id === typeId) {
        return true;
      }
    }

    return false;
  }

  const dispatchError = (transKey, error) => {
    mainDispatchCallback({
      type: SET_NOTIFICATIONS,
      notifications: [...notifications,
        {
          type: 'error',
          text: t(transKey),
          error: error
        }
      ]
    });
  };

  useEffect(() => {
    if (!!baseUrl) {
      setLoading(true);
      let api = new PrimusApi(baseUrl);
      let items = new ListSelectorItems(api, t);
      items.getListItems().then((result) => {
        setAvailableLists(result || {});
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
        dispatchError('table.default.jobs.errorGettingListItems', e);
      });
    } else {
      setLoading(false);
    }
  }, [baseUrl])

  useEffect(() => {
  if (!Array.isArray(availableLists) && !!baseUrl) {
      let api = new PrimusApi(baseUrl);
      let params = new SolrSearchParams();

      params.setObjectType('ct_23');
      params.setFields('artifact_id, artifact_name')
      params.setResultLimit(1000000);

      api.search(params).then((result, error) => {
        if (result?.artifacts) {
          let tempList = [];
          result.artifacts.forEach((artifact) => {
            if (isKnownAgentType(artifact.artifact_id)
              || artifact.artifact_id === 'ct_23-9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c') {
              tempList.push({
                id: artifact.artifact_id,
                label: artifact.artifact_name
              });
            }
          });
          primusDispatchCallback({
            type: SET_KNOWN_AGENT_TYPES,
            knownAgentTypes: tempList
          });
        } else throw new Error(error || result?.message);
      }).catch((e) => dispatchError('table.default.jobs.errorGettingAgentTypes', e));
    }
  }, [availableLists]);


  const onListChange = (event) => {
    let selected = null;

    Object.keys(availableLists).forEach((listKey) => {
      availableLists[listKey].items.forEach((list) => {
        if ((list.id && list.id === event.target.value)
            || (list.hasOwnProperty('objectType') && list.objectType === event.target.value)) {
          selected = list;
        }
      });
    });

    primusDispatchCallback({
      type: SET_SELECTED_LIST,
      selectedList: selected.id === null ? selected.objectType : selected.id,
      selectedListName: selected.label
    });

    tableDispatchCallback({
      type: RESET_TABLE_STATE
    });
  };

  return (
    <>
      {!loading ? (
        <Fade in={true} unmountOnExit={true}>
          <TextField
            label={t('menu.actionHeader.lists')}
            onChange={onListChange}
            select
            sx={{
              width: 'fit-content',
              minWidth: '240px'
            }}
            defaultValue=''
            disabled={!Object.keys(availableLists).length}
            value={selectedList}
          >
            {availableLists.properName?.items.length > 0 && (
              <ListSubheader>
                {t(availableLists.properName.groupNameTranslationKey).toUpperCase()}
              </ListSubheader>
            )}
            {availableLists.properName?.items.map((list) => (
              <MenuItem
                key={list.id}
                value={list.id}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                  }}
                >
                  <List fontSize='10px' />
                  {`${t(list.label)} (${list.count})`}
                </Box>
              </MenuItem>
            ))}

            {availableLists.namedObjects?.items.length > 0 && (
              <ListSubheader>
                {t(availableLists.namedObjects.groupNameTranslationKey).toUpperCase()}
              </ListSubheader>
            )}
            {availableLists.namedObjects?.items.map((list) => (
              <MenuItem
                key={list.id}
                value={list.id}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                  }}
                >
                  <List fontSize='10px' />
                  {`${t(list.label)} (${list.count})`}
                </Box>
              </MenuItem>
            ))}

            {availableLists.name?.items.length > 0 && (
              <ListSubheader>
                {t(availableLists.name.groupNameTranslationKey).toUpperCase()}
              </ListSubheader>
            )}
            {availableLists.name?.items.map((list) => (
              <MenuItem
                key={list.id}
                value={list.id}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                  }}
                >
                  <List fontSize='10px' />
                  {`${t(list.label)} (${list.count})`}
                </Box>
              </MenuItem>
            ))}

            {availableLists.terms?.items.length > 0 && (
              <ListSubheader>
                {t(availableLists.terms.groupNameTranslationKey).toUpperCase()}
              </ListSubheader>
            )}
            {availableLists.terms?.items.map((list) => (
              <MenuItem
                key={list.objectType}
                value={list.objectType}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                  }}
                >
                  <List fontSize='10px' />
                  {`${t(list.label)} (${list.count})`}
                </Box>
              </MenuItem>
            ))}
            {availableLists.classifications?.items.length > 0 && (
              <ListSubheader>
                {t(availableLists.classifications.groupNameTranslationKey).toUpperCase()}
              </ListSubheader>
            )}
            {availableLists.classifications?.items.map((list) => (
              <MenuItem
                key={list.id}
                value={list.id}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                  }}
                >
                  <List fontSize='10px' />
                  {`${t(list.label)} (${list.count})`}
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Fade>
      ) : (
        <Fade in={true} unmountOnExit={true}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '.5rem .625rem',
              width: '25%'
            }}
          >
            <CircularProgress />
          </Box>
        </Fade>
      )}
    </>
  )
}