// TODO: declare without concept type prefix?
export const HISTORY_EVENT_TYPE_BIRTH_ID = 'ct_19-a100bf41-f035-49c5-b71b-f5b4de1655a5'
export const HISTORY_EVENT_TYPE_DEATH_ID = 'ct_19-a100bf41-f036-49c5-b71b-f5b4de1655a5'

export const SUPEROBJECT_TYPE_CONCEPT_ID = 'ct_128-d796ee93-1400-4b80-92c5-6f2913b559d5'
export const SUPEROBJECT_TYPE_AGENT_ID = 'ct_128-d796ee93-0200-4b80-92c5-6f2913b559d5'
export const SUPEROBJECT_HISTORY_EVENT_TYPE_ID = 'ct_128-d796ee93-1900-4b80-92c5-6f2913b559d5'

export const AUTHORITY_KULTURNAV_ID = 'ct_166-a3c02acc-be2a-450b-bc96-fa35cf579104'
export const AUTHORITY_ARCHAEOLOGY_ID = 'ct_166-2a8d280b-2e30-4715-a60c-881917e97d3a'
export const AUTHORITY_SITULA_ID = 'ct_166-80c4f3a2-86bc-4fad-a1f3-27d3f2af90a1'

export const PRIMUS_APPLICATION_ID = '06571733-ed99-4a83-92bf-78e64236938a'
export const WASH_APPLICATION_ID = 'be798b01-973b-4f75-95a2-607a560e0a6c'
export const IMPORT_APPLICATION_ID = '475a185e-dd95-4b22-8d35-7542753a9fbc'
export const KULTURNAV_APPLICATION_ID = '047455b7-c045-4d0f-93c6-291f332a0507'

export const PERSON_TYPE_ID = 'ct_23-784b329e-5a5d-4db7-ab34-f316d0b54f34'
export const OTHER_TYPE_ID = 'ct_23-9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c'
export const UNKNOWN_TYPE_ID = 'ct_23-3abed8a2-9818-4206-a8b6-55c34d45cfaf'
export const FIRM_TYPE_ID = 'ct_23-19aa878b-5bda-4847-b0e6-39c34f0b2ee2'
export const INSTITUTION_TYPE_ID = 'ct_23-7d306c1b-ba63-4218-acef-47e9a815b694'
export const ORGANIZATION_TYPE_ID = 'ct_23-fb8b920d-b709-4783-ab98-f6fcc1d0ea6f'

export const LOOKUP_LISTS_RIGHTS_ID = 'ct_139-193fabb7-231e-481b-ae40-968026971003'
export const GLOBAL_LISTS_RIGHTS_ID = 'ct_139-193fabb7-231e-480b-ae40-968026971003'
export const NAMES_RIGHTS_ID = 'ct_139-0ca0798d-a414-47dd-a8a7-7681bdc22535'
export const PLACE_RIGHTS_ID = 'ct_139-0ca0798d-a416-47dd-a8a7-7681bdc22535'

export const COLLECTIONS_WRITE_RIGHT_ID = 'ct_85-70db0c2a-f975-4eb3-a7d8-c82cee1a2b51'
export const COLLECTIONS_READ_RIGHT_ID = 'ct_85-70db0c2a-f975-4eb1-a7d8-c82cee1a2b51'

export const authorityMap = {
  [AUTHORITY_KULTURNAV_ID]: 'KulturNav',
  [AUTHORITY_ARCHAEOLOGY_ID]: 'Arkeologi',
  [AUTHORITY_SITULA_ID]: 'Situla'
};

export const applicationMap = {
  [PRIMUS_APPLICATION_ID]: 'Primus',
  [WASH_APPLICATION_ID]: 'Datavask',
  [IMPORT_APPLICATION_ID]: 'PrimusImport',
  [KULTURNAV_APPLICATION_ID]: 'KulturNav'
};

export const rightsMap = {
  [LOOKUP_LISTS_RIGHTS_ID]: 'primusRights.rights.LookupLists',
  [GLOBAL_LISTS_RIGHTS_ID]: 'primusRights.rights.GlobalLists',
  [NAMES_RIGHTS_ID]: 'primusRights.rights.Names',
  [PLACE_RIGHTS_ID]: 'primusRights.rights.Places',
}