import { FilterAltOff, Unpublished } from '@mui/icons-material';
import { Box, Divider, IconButton, Tooltip } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton as ShowHideColumnsButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as ToggleGlobalFilterButton
} from 'material-react-table';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SET_TABLE_DATA_SELECTION,
  SET_TABLE_FILTER,
  SET_TABLE_FILTER_NAME,
  SET_TABLE_PAGINATION,
  SET_TABLE_PINNED_ROWS,
  SET_TABLE_SORTING,
  useTableDispatch,
  useTableState
} from "../../contexts/TableContext";
import { generateTableTranslation } from '../../utils/tvattTableTranslation';
import { ActionButton } from '../ActionHeader/ActionButton';
import { ListSelector } from '../ActionHeader/ListSelector';
import { StatusSelector } from "../ActionHeader/StatusSelector";
import { DetailPanel } from '../DetailPanels/DetailPanel';
import { TermDetailPanel } from "../DetailPanels/TermDetailPanel";
import { FilterHeader } from '../FilterHeader/FilterHeader';
import { JobHeader } from '../JobHeader/JobHeader';


export const TvattTable = ({
  columns,
  enableEditing = false,
  groupBy = [],
  isLoading = false,
  onRowSaved = null,
  sortBy = null,
  muiTableBodyCellProps
}) => {
  const { data, filter, pagination, pinnedRows, selectedData, sorting } = useTableState();
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { t } = useTranslation('translations');

  const tableLocalization = generateTableTranslation(t);

  return (
    <Box
      id='JobsAndTable-FlexContainer'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <FilterHeader />
      <JobHeader />
      <MaterialReactTable
        autoResetPageIndex={false}
        columns={columns}
        columnFilterDisplayMode='popover'
        data={data}
        editDisplayMode='row'
        enableColumnFilterModes={true}
        enableColumnResizing={true}
        enableEditing={enableEditing}
        enableGrouping={groupBy.length > 0}
        enableFacetedValues={true}
        enableColumnPinning={true}
        enableRowPinning={true}
        rowPinningDisplayMode='top'
        enableRowSelection={true}
        enableSelectAll={true}
        enableStickyHeader={true}
        enableSubRowSelection={true}
        filterFns={{
          customGlobalFn: (row, id, filterValue) => {
            if (filterValue) {
              return row.original.caption?.toLowerCase().includes(filterValue?.toLowerCase() ?? '')
                || row.original.beginDate?.includes(filterValue)
                || row.original.endDate?.includes(filterValue)
            } else {
              return true;
            }
          },
          captionContainsCaps: (row, id, filterValue) => {
            return row.original.caption?.includes(filterValue)
          }
        }}
        getRowId={(row) => row.id}
        globalFilterFn='customGlobalFn'
        initialState={{
          columnOrder: [
            'mrt-row-expand',
            'mrt-row-select',
            'caption',
            'code',
            'hierarchy',
            'agentTypeIdValue',
            'authority',
            'authorityIdValue',
            'beginDate',
            'endDate',
            'invalid',
            'inValid',
            'frequency',
            'warning',
            'errors',
            'duplicates',
            'matchings',
            'bookmarked',
            'mrt-row-pin'
          ],
          columnVisibility: { edit: false },
          density: 'compact',
          grouping: groupBy
        }}
        layoutMode='grid'
        localization={tableLocalization}
        muiTableContainerProps={{
          sx: {
            flexGrow: '1',
            flexShrink: '1',
            flexBasis: 'auto',
            minWidth: 'calc(100vw - 240px)',
            maxHeight: 'none'
          }
        }}
        muiTopToolbarProps={{
          sx: {
            flexGrow: '0',
            flexShrink: '0',
            flexBasis: 'auto',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '& .MuiCollapse-root': {
              width: '100%',
            },
            '& .MuiBox-root:nth-of-type(2)': {
              alignSelf: 'center',
            },
            '& .MuiDivider-root': {
              margin: '0 .5rem',
            },
          }
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 25, 50, 100, 200, 500]
        }}
        muiTablePaperProps={({ table }) => ({
          style: {
            borderRadius: '0',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            flexShrink: '1',
            flexBasis: 'auto',
            overflow: 'auto',
            width: '100%',
            zIndex: table.getState().isFullScreen ? 1150 : undefined,
          }
        })}
        onColumnFiltersChange={(state) => {
          tableDispatchCallback({ type: SET_TABLE_FILTER, filter: state(filter) });
        }}
        onEditingRowSave={onRowSaved}
        onPaginationChange={(state) => {
          tableDispatchCallback({
            type: SET_TABLE_PAGINATION,
            pagination: state(pagination)
          })
        }}
        onRowPinningChange={(state) => {
          tableDispatchCallback({
            type: SET_TABLE_PINNED_ROWS,
            pinnedRows: state(pinnedRows)
          });
        }}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            zIndex: row.getIsPinned() ? 100 : 0,
            backgroundColor: 'white',
          }
        })}
        onRowSelectionChange={(state) => {
          tableDispatchCallback({
            type: SET_TABLE_DATA_SELECTION,
            selectedData: state(selectedData)
          })
        }}
        onSortingChange={(state) => {
          tableDispatchCallback({
            type: SET_TABLE_SORTING,
            sorting: state(sorting)
          })
        }}
        selectAllMode='page'
        state={{
          columnFilters: filter,
          isLoading: isLoading,
          pagination: pagination,
          rowPinning: pinnedRows,
          rowSelection: selectedData,
          sorting: sortBy || sorting
        }}
        renderDetailPanel={({ row }) => row.original.hasOwnProperty('code')
          ? <TermDetailPanel rowData={row.original} toggleIsExpanded={row.toggleExpanded} />
          : <DetailPanel row={row} />
        }
        muiDetailPanelProps={{
          sx: {
            width: '100%',
            '.MuiCollapse-vertical': {
              width: '100%'
            }
          }
        }}
        renderToolbarInternalActions={({ table }) => {
          // DEBUG: Kommenter ut columnOrder fra initial state og log getallcolumns for å legge til flere kolonner i order
          // console.log(table.getAllColumns());
          return (
            <>
              <Tooltip title={t('table.default.removeFilters')}>
                <IconButton
                  onClick={() => {
                    tableDispatchCallback({ type: SET_TABLE_FILTER, filter: [] });
                    tableDispatchCallback({ type: SET_TABLE_FILTER_NAME, filterName: 'none' });
                    tableDispatchCallback({ type: SET_TABLE_SORTING, sorting: [{ id: 'caption', desc: false }]})
                    table.resetColumnFilters();
                    table.resetGlobalFilter();
                  }}
                >
                  <FilterAltOff />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('table.default.unselectAll')}>
                <span>
                  <IconButton
                    disabled={Object.keys(selectedData).length < 1}
                    onClick={() => {
                      tableDispatchCallback({
                        type: SET_TABLE_DATA_SELECTION,
                        selectedData: {}
                      })
                    }}
                  >
                    <Unpublished />
                  </IconButton>
                </span>
              </Tooltip>

              <Divider orientation='vertical' variant='middle' sx={{ borderColor: 'rgba(0, 0, 0, 0.50)' }} />

              <ToggleGlobalFilterButton table={table} />
              <ShowHideColumnsButton table={table} />
              <ToggleFullScreenButton table={table} />
            </>
          )
        }}
        renderTopToolbarCustomActions={() => (
          <Box
            id='TopToolbarCustomActions'
            sx={{
              alignItems: 'center',
              display: 'flex',
              mt: 1,
              gap: 1,
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <ListSelector />
            <StatusSelector />
            <ActionButton />
          </Box>
        )}
        muiTableBodyCellProps={muiTableBodyCellProps}
      />
    </Box>
  )
}