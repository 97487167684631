import { ExpandMore, FilterAlt } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from "../../contexts/MainContext";
import { SET_SELECTED_LIST_STATUS, usePrimusDispatch, usePrimusState } from "../../contexts/PrimusContext";
import {
  SET_INDEXED_DATA,
  SET_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_FILTER_NAME,
  SET_TABLE_SORTING,
  useTableDispatch,
  useTableState
} from "../../contexts/TableContext";
import { clearEditedRows, dumpAllIndexedData } from "../../services/IndexedDbService";
import { PrimusApi } from "../../services/PrimusApi";
import { getIsTerm } from "../../utils/termUtils";

export const FilterList = () => {
  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const primusDispacth = usePrimusDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const primusDispatchCallback = useCallback(primusDispacth,[primusDispacth]);

  const location = useLocation();
  const { notifications } = useMainState();
  const { filterName } = useTableState();
  const { baseUrl, selectedList, selectedListStatus, versionMajor, versionMinor } = usePrimusState();
  const { t } = useTranslation('translations');

  const [isTerm, setIsTerm] = useState(getIsTerm(selectedList))

  useEffect(() => setIsTerm(getIsTerm(selectedList)), [selectedList]);

  const clearEditedFromDb = () => {
    if (window.confirm(t('menu.filters.edit.confirmClearEdit'))) {
      clearEditedRows().then(dumpAllIndexedData).then((data) => {
        tableDispatchCallback({
          type: SET_INDEXED_DATA,
          indexedData: data
        });
        return data
      }).then((indexedData) => {
        let api = new PrimusApi(baseUrl);
        api.getSelectedListData(indexedData, selectedList, t, false, selectedListStatus,
                                (versionMajor > 0 || versionMinor > 222)).then((list) => {
          tableDispatchCallback({
            type: SET_TABLE_DATA,
            data: [...list]
          });
        }).catch((e) => {
          mainDispatchCallback({
            type: SET_NOTIFICATIONS,
            notifications: [...notifications,
              {
                text: t('table.default.jobs.errorSettingTableData'),
                type: 'error',
                error: e,
              }
            ]
          });
        });
      });
    }
  };

  const handleFilterChange = (name) => {
    let filter = [];
    let sorting = [];

    switch(name) {
      case 'bookmarked': {
        filter = [
          {
            filterName: 'bookmarked',
            id: 'bookmarked',
            value: true
          }
        ];
        sorting = [
          {
            id: 'caption',
            desc: true
          }
        ]
        break;
      }

      case 'edit': {
        filter = [
          {
            filterName: 'edit',
            id: 'edit',
            value: true
          }
        ];
        sorting = [
          {
            id: 'edit',
            desc: true
          }
        ];
        primusDispatchCallback({
          type: SET_SELECTED_LIST_STATUS,
          selectedListStatus: 'all'
        });
        break;
      }

      case 'matching': {
        filter = [
          {
            filterName: 'matching',
            id: 'authorityIdValue',
            value: false
          },
          {
            id: 'inValid',
            value: false
          },
          {
            id: 'duplicates',
            value: false
          },
          {
            filterName: 'errors',
            id: 'errors',
            value: [false]
          }
        ];
        if (!isTerm) filter.push({ id: 'beginDate', value: "or" });
        sorting = [
          {
            id: 'frequency',
            desc: true
          }
        ]
        break;
      }

      case 'status': {
        filter = [
          {
            id: 'frequency',
            value: false
          },
          {
            id: 'inValid',
            value: true
          }
        ];
        break;
      }

      case 'type': {
        filter = [
          {
            id: 'agentTypeIdValue',
            value: ['Ukjent']
          },
          {
            id: 'frequency',
            value: true
          }
        ];
        break;
      }

      case 'invalid': {
        filter = [
          {
            id: 'frequency',
            value: true
          },
          {
            id: 'inValid',
            value: true
          }
        ];
        break;
      }

      case 'error': {
        filter = [
          {
            filterName: 'errors',
            id: 'errors',
            value: [true]
          }
        ];
        sorting = [
          {
            id: 'frequency',
            desc: true
          }
        ]
        break;
      }

      case 'none': {
        filter = [];
        sorting = [
          {
            id: 'caption',
            desc: false
          }
        ]
        break;
      }

      default: {
        throw new Error(`[FilterList] Unknown filtername: ${name}`);
      }
    }

    tableDispatchCallback({type:SET_TABLE_FILTER_NAME, filterName: filterName === name ? 'none' : name});
    tableDispatchCallback({type:SET_TABLE_FILTER, filter: filterName === name ? [] : filter});
    tableDispatchCallback({type:SET_TABLE_SORTING, sorting:sorting})
  }

  return (
    <Collapse in={location.pathname === '/'} unmountOnExit={true}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <FilterAlt />
          <Typography>
            {t('menu.filters.header')}
          </Typography>
        </AccordionSummary>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Tooltip title={t('menu.filters.bookmarked.tooltip')}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterName === 'bookmarked'}
                    onChange={() => handleFilterChange(filterName === 'bookmarked' ? 'none' : 'bookmarked')}
                  />
                }
                label={t('menu.filters.bookmarked.name')}
              />
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('menu.filters.bookmarked.info')}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Tooltip title={t('menu.filters.matching.tooltip')}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterName === 'matching'}
                    onChange={() => handleFilterChange(filterName === 'matching' ? 'none' : 'matching')}
                  />
                }
                label={t('menu.filters.matching.name')}
              />
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('menu.filters.matching.info')}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Tooltip title={t('menu.filters.error.tooltip')}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterName === 'error'}
                    onChange={() => handleFilterChange(filterName === 'error' ? 'none' : 'error')}
                  />
                }
                label={t('menu.filters.error.name')}
              />
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('menu.filters.error.info')}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Tooltip title={t('menu.filters.edit.tooltip')}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterName === 'edit'}
                    onChange={() => handleFilterChange(filterName === 'edit' ? 'none' : 'edit')}
                  />
                }
                label={t('menu.filters.edit.name')}
              />
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('menu.filters.edit.info')}</Typography>
            <Button
              onClick={clearEditedFromDb}
              variant='contained'
              color='error'
              fullWidth
              size='small'
              sx={{ marginTop: 1 }}
            >
              {t('menu.filters.edit.clearEdit')}
            </Button>
          </AccordionDetails>
        </Accordion>

        {(selectedListStatus === 'all' || selectedListStatus ==='invalid') && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Tooltip title={t('menu.filters.invalid.tooltip')}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterName === 'invalid'}
                      onChange={() => handleFilterChange(filterName === 'invalid' ? 'none' : 'invalid')}
                    />
                  }
                  label={t('menu.filters.invalid.name')}
                />
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('menu.filters.invalid.info')}</Typography>
            </AccordionDetails>
          </Accordion>
        )}
      </Accordion>
    </Collapse>
  );
}