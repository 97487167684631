import React, { createContext, useContext, useReducer } from 'react';
import 'whatwg-fetch';

import { localStorageKeys } from "../declarations/localStorageKeys";

const PrimusDispatchContext = createContext(null);
const PrimusStateContext = createContext(null);

const initialState = {
  baseUrl: localStorage.getItem(localStorageKeys.selectedBaseUrl) || '',
  database: localStorage.getItem(localStorageKeys.selectedDatabase) || '',
  descriptionObject: null,
  knownAgentTypes: [],
  language: 'no',
  selectedList: localStorage.getItem(localStorageKeys.selectedPrimusList) || '',
  selectedListName: localStorage.getItem(localStorageKeys.selectedPrimusListName) || '',
  selectedListStatus: 'valid',
  versionMajor: -1,
  versionMinor: -1,
  operations: []
}

const Reducer = (state, action) => {
  switch (action.type) {
    case LOAD_INSTANCE_CONFIG: {
      return {
        ...state,
        language: action.language,
        versionMajor: action.versionMajor,
        versionMinor: action.versionMinor
      }
    }

    case SET_DATABASE: {
      window.localStorage.setItem(localStorageKeys.selectedBaseUrl, action.baseUrl);
      window.localStorage.setItem(localStorageKeys.selectedDatabase, action.database);
      window.localStorage.setItem(localStorageKeys.selectedPrimusList, '');
      window.localStorage.setItem(localStorageKeys.selectedPrimusListName, '');

      return {
        ...state,
        baseUrl: action.baseUrl,
        database: action.database,
        descriptionObject: null,
        knownAgentTypes: [],
        language: action.language,
        selectedList: '',
        selectedListName: '',
        selectedListStatus: 'valid',
        versionMajor: action.versionMajor,
        versionMinor: action.versionMinor
      }
    }

    case SET_DESCRIPTION_OBJECT: {
      return {
        ...state,
        descriptionObject: action.descriptionObject
      }
    }

    case SET_KNOWN_AGENT_TYPES: {
      return {
        ...state,
        knownAgentTypes: action.knownAgentTypes
      }
    }

    case SET_SELECTED_LIST: {
      window.localStorage.setItem(localStorageKeys.selectedPrimusList, action.selectedList);
      window.localStorage.setItem(localStorageKeys.selectedPrimusListName, action.selectedListName);

      return {
        ...state,
        selectedList: action.selectedList,
        selectedListName: action.selectedListName,
        selectedListStatus: 'valid'
      }
    }

    case SET_SELECTED_LIST_STATUS: {
      return {
        ...state,
        selectedListStatus: action.selectedListStatus
      }
    }

    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.operations
      }

    default: {
      return {
        ...state
      }
    }
  }
}

export function PrimusContextProvider({ children }) {
  const [state, dispatch] = useReducer(Reducer, {...initialState}, undefined);

  if (!!state.baseUrl && state.versionMajor === -1) {
    fetch(
      `${state.baseUrl}/cms_api/v1.0/setting/get_client_config`,
      {
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }),
        method: 'GET'
      }
    ).then((config) => {
      return config.json();
    }).then((config) => {
      dispatch({
        type: LOAD_INSTANCE_CONFIG,
        language: config.CLIENT_LANGUAGE,
        versionMajor: parseInt(config.VERSION.split('.')[1]),
        versionMinor: parseInt(config.VERSION.split('.')[2]),
      });
    });
  }

  // Comment this back in if operations from generated in backend are to be used
  // if (!!state.baseUrl && !state.operations?.length) {
  //   let api = new PrimusApi(state.baseUrl);
  //   api.getOperations().then((res) => {
  //     dispatch({
  //       type: SET_OPERATIONS,
  //       operations: res.operations
  //     })
  //   })
  // }

  return (
    <PrimusStateContext.Provider value={state}>
      <PrimusDispatchContext.Provider value={dispatch}>
        {children}
      </PrimusDispatchContext.Provider>
    </PrimusStateContext.Provider>
  )
}

export const usePrimusDispatch = () => {
  const context = useContext(PrimusDispatchContext);

  if (context === undefined) {
    throw new Error('[PrimusContext] PrimusDispatch must be used within a PrimusContextProvider');
  } else {
    return context;
  }
}

export const usePrimusState = () => {
  const context = useContext(PrimusStateContext);

  if (context === undefined) {
    throw new Error('[PrimusContext] PrimusState must be used within a PrimusContextProvider');
  }

  return context;
}

const LOAD_INSTANCE_CONFIG = 'loadInstanceConfig';

export const SET_DATABASE = 'setBaseUrl';
export const SET_DESCRIPTION_OBJECT = 'setDescriptionObject';
export const SET_KNOWN_AGENT_TYPES ='setKnownAgentTypes';
export const SET_SELECTED_LIST = 'setSelectedList';
export const SET_SELECTED_LIST_STATUS = 'setSelectedListStatus';
export const SET_OPERATIONS = 'setOperations';
