import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from '../../contexts/MainContext';
import { usePrimusState } from "../../contexts/PrimusContext";
import {
  SET_INDEXED_DATA,
  SET_TABLE_DATA,
  SET_TABLE_PINNED_ROWS,
  useTableDispatch,
  useTableState
} from "../../contexts/TableContext";
import { HISTORY_EVENT_TYPE_BIRTH_ID, HISTORY_EVENT_TYPE_DEATH_ID, PERSON_TYPE_ID } from "../../declarations/UUIDs";
import { dumpAllIndexedData, toggleEditedRow } from "../../services/IndexedDbService";
import { PrimusApi } from '../../services/PrimusApi';
import {
  formatDateWithPrecision,
  getCaption,
  getDuplicatesData,
  reAnalyzeWasherRow
} from "../../utils/primusDataHandling";
import { ClickAlert } from "./ClickAlert";
import { DetailPanelActionMenu } from "./DetailPanelActionMenu";
import { DetailPanelUsageList } from "./DetailPanelUsageList";
import {
  getClickableItem,
  getDateTextField,
  getEventObject,
  getNameFromArtifact,
  getPrimusUrl,
  isDateChanged,
  getDatePrecision,
  getDateString
} from "./DetailPanelUtils";
import { SidePanelUsageList } from "./SidePanelUsageList";

export const DetailPanel = ({ disableEditing=false, row }) => {
  const { original } = row;
  const [rowData, setRowData] = useState(original);
  const [typeValue, setTypeValue] = useState({
    id: rowData.agentTypeId,
    label: rowData.agentTypeIdValue
  });
  const [beginDate, setBeginDate] = useState(original.beginDate || "")
  const [endDate, setEndDate] = useState(original.endDate || "")
  const [loading, setLoading] = useState(false);
  const [loadingSideActions, setLoadingSideActions] = useState(false);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { notifications } = useMainState();
  const { baseUrl, knownAgentTypes, versionMajor, versionMinor } = usePrimusState();
  const { data, pinnedRows, indexedData } = useTableState();
  const { t } = useTranslation('translations');
  const [usages, setUsages] = useState([])

  const [copyAlertOpen, setCopyAlertOpen] = useState(false);
  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 });

  const dispatchError = (transKey, error, transOptions) => {
    mainDispatchCallback({
      type: SET_NOTIFICATIONS,
      notifications: [...notifications,
        {
          type: 'error',
          text: t(transKey, transOptions),
          error: error
        }
      ]
    });
  };

  const handleRowChange = (event) => {
    setRowData((prev) => ({...prev, [event.target.name]: event.target.value}));
  }

  const handleChangeBeginDate = (event) => {
    const inputValue = event.target.value;
    const precision = getDatePrecision(inputValue)
    setBeginDate(inputValue);
    if (precision) {
      setRowData((prev) => ({ ...prev, beginDate: inputValue, beginDatePrecision: precision }));
    }
  };

  const handleChangeEndDate = (event) => {
    const inputValue = event.target.value;
    const precision = getDatePrecision(inputValue)
    setEndDate(inputValue);
    if (precision) {
      setRowData((prev) => ({ ...prev, endDate: inputValue, endDatePrescison: precision }));
    }
  };

  const handleSaveRow = async () => {
    setLoading(true);
    let api = new PrimusApi(baseUrl);
    async function updateDateField(date, oldDate, eventId, artifact, isEnd) {
      try {
        const dateString = getDateString(date);
        const datePrecision = getDatePrecision(date);
        if (!isDateChanged(oldDate, date)) return artifact;
        let tempEvent
        let tempObject = artifact;
        if (!Array.isArray(tempObject.history_events)) tempObject.history_events = [];
        let index = tempObject.history_events.findIndex((e) => eventId === e.history_event_id);
        if (index >= 0) {
          tempEvent = tempObject.history_events[index];
          if (!tempEvent.timespan_historic) {
            tempEvent.timespan_historic = {
              object_type: 'TimespanHistoric',
              from_date_precision: datePrecision,
              meta_type: 'sub_model',
            }
          }
          tempEvent.from_date = dateString || null;
          tempEvent.from_date_precision = datePrecision;
          tempEvent.artifact_id = tempEvent.history_event_id;
          tempEvent.timespan_historic.from_date = dateString || null;
          tempEvent.timespan_historic.from_date_precision = datePrecision;
          await api.putArtifact(tempEvent).then((res) => {
            tempEvent.history_event_id = res.artifact_id;
            tempObject.history_events[index] = { ...tempObject.history_events[index], ...tempEvent };
          })
        } else {
          tempEvent = getEventObject(dateString, datePrecision, isEnd);
          await api.putArtifact(tempEvent).then((res) => {
            tempEvent.history_event_id = res.artifact_id;
            tempObject.history_events.push(tempEvent);
          })
        }
        return tempObject;
      } catch (e) {
        dispatchError('table.default.jobs.updateDateError', e);
      }
    }

    api.getArtifact(rowData.id).then((artifact) => {
      let tempArtifact = artifact;
      if (typeValue && typeValue.id === PERSON_TYPE_ID) {
        tempArtifact.first_name = { ...tempArtifact.first_name, first_name: rowData.firstName };
        tempArtifact.middle_name = { ...tempArtifact.middle_name, middle_name: rowData.middleName };
        tempArtifact.last_name = { ...tempArtifact.last_name, last_name: rowData.lastName };
        tempArtifact.name = { ...tempArtifact.name, name: getNameFromArtifact(artifact) };
      } else {
        tempArtifact.name = { ...tempArtifact.name, name: rowData.name };
      }
      tempArtifact.description = { ...tempArtifact.description, description: rowData.description }
      tempArtifact.agent_type_id = typeValue.id;
      tempArtifact.agent_type_id_value = typeValue.label;
      return tempArtifact;
    }).then((artifact) => updateDateField(rowData.beginDate, original.beginDate, rowData.beginDateId, artifact, false))
      .then((artifact) => updateDateField(rowData.endDate, original.endDate, rowData.endDateId, artifact, true))
      .then((artifact) => {
        if (original.agentTypeId !== artifact.agent_type_id && pinnedRows?.top?.length) {
          let tempPinned = pinnedRows.top;
          let index = tempPinned.findIndex((row) => row === artifact.artifact_id);
          if (index >= 0) {
            tableDispatchCallback({
              type: SET_TABLE_PINNED_ROWS,
              pinnedRows: {
                top: [...tempPinned.slice(0, index), ...tempPinned.slice(index + 1)],
                bottom: pinnedRows.bottom
              }
            });
          }
        }
        api.putArtifact(artifact).then(() => {
          updateTable(artifact);
          setLoading(false);
          row.toggleExpanded(false);
        }).catch((e) => dispatchError('table.default.jobs.putArtifactError', e, { name: artifact.name?.name }));
      }).catch((e) => dispatchError('table.default.jobs.getArtifactError', e, { name: rowData.name }));
  };

  const updateTable = (artifact, usageNr) => {
    let tempTable = data;
    let index = data.findIndex((item) => item.id === artifact.artifact_id);
    if (artifact && index >= 0) {
      tempTable[index].name = artifact.name?.name || getNameFromArtifact(artifact);
      tempTable[index].firstName = artifact.first_name?.first_name;
      tempTable[index].middleName = artifact.middle_name?.middle_name;
      tempTable[index].lastName = artifact.last_name?.last_name;
      tempTable[index].description = artifact.description?.description;
      tempTable[index].solrData = artifact.artifact_name || artifact.name?.name;

      let beginEvent = artifact.history_events?.findLast((event) => event.type_id === HISTORY_EVENT_TYPE_BIRTH_ID);
      let endEvent = artifact.history_events?.findLast((event) => event.type_id === HISTORY_EVENT_TYPE_DEATH_ID);
      if (beginEvent) {
        tempTable[index].beginDateId = beginEvent.history_event_id || '';
        tempTable[index].beginDatePrecision = beginEvent.from_date_precision || 'year';
        let beginDate = beginEvent.timespan_historic?.from_date || beginEvent.from_date;
        tempTable[index].beginDate = beginDate
          ? formatDateWithPrecision(beginDate, tempTable[index].beginDatePrecision)
          : '';
      } else {
        tempTable[index].beginDate = '';
        tempTable[index].beginDateId = '';
        tempTable[index].beginDatePrecision = '';
      }
      if (endEvent) {
        tempTable[index].endDateId = endEvent.history_event_id || '';
        tempTable[index].endDatePrecision = endEvent.from_date_precision || 'year';
        let endDate = endEvent.timespan_historic?.from_date || endEvent.from_date;
        tempTable[index].endDate = endDate ? formatDateWithPrecision(endDate, tempTable[index].endDatePrecision) : '';
      } else {
        tempTable[index].endDate = '';
        tempTable[index].endDateId = '';
        tempTable[index].endDatePrecision = '';
      }

      tempTable[index].caption = getCaption(artifact, tempTable[index].beginDate, tempTable[index].endDate);

      tempTable[index].agentTypeId = artifact.agent_type_id || '';
      tempTable[index].agentTypeIdValue = artifact.agent_type_id_value || '';

      let duplicatesData = getDuplicatesData(tempTable, index, indexedData.markedNoDuplicatesRows);
      tempTable[index].duplicates = duplicatesData.duplicates;
      tempTable[index].duplicateData = duplicatesData.duplicateData;

      tempTable[index].edit = new Date().toISOString();
      toggleEditedRow(tempTable[index]).then(dumpAllIndexedData)
        .then((data) => {
          tableDispatchCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          });
        });


      tempTable[index].frequency = usageNr ?? tempTable[index].frequency;

      let analysisData = reAnalyzeWasherRow(t, tempTable[index]);
      tempTable[index].errors = analysisData?.errors;
      tempTable[index].messages = analysisData?.messages;

      tableDispatchCallback({
        type: SET_TABLE_DATA,
        data: [...tempTable]
      });
    }
  };

  const handleClickField = (event, objid, openIn) => {
    if (objid) {
      if (!openIn) {
        const rect = event.target.getBoundingClientRect();
        setClickCoordinates({ x: rect.left, y: rect.top });
        navigator.clipboard.writeText(objid).then(() => setCopyAlertOpen(true));
      } else {
        window.open(`${getPrimusUrl()}/search/artifact?artifactId=${objid}`);
      }
    }
  };

  return (
    <Box display='flex'  width='100%' gap={2}>
      <Box alignItems='center' display='flex' flexDirection='column' gap={2} flex='1'>
        <Autocomplete
          disableClearable
          disabled={rowData.hasAuthority || disableEditing || (versionMajor < 1 && versionMinor < 236)}
          fullWidth
          getOptionKey={(option) => option.id}
          onChange={(event, selection) => setTypeValue(selection)}
          options={knownAgentTypes}
          value={typeValue}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) =>
            <TextField
              {...params}
              name={'type'}
              label={t('table.default.detailpane.type')}
            />
          }
        />

        {typeValue && typeValue.id === PERSON_TYPE_ID ? (
          <Box display='flex' flexDirection='row' width='100%' gap={2}>
            <TextField
              disabled={rowData.hasAuthority || disableEditing || (versionMajor < 1 && versionMinor < 236)}
              fullWidth
              label={t('table.default.detailpane.firstName')}
              name={'firstName'}
              onChange={handleRowChange}
              value={rowData.firstName || ''}
            />
            <TextField
              disabled={rowData.hasAuthority || disableEditing || (versionMajor < 1 && versionMinor < 236)}
              fullWidth
              label={t('table.default.detailpane.middleName')}
              name={'middleName'}
              onChange={handleRowChange}
              value={rowData.middleName || ''}
            />
            <TextField
              disabled={rowData.hasAuthority || disableEditing || (versionMajor < 1 && versionMinor < 236)}
              fullWidth
              label={t('table.default.detailpane.lastName')}
              name={'lastName'}
              onChange={handleRowChange}
              value={rowData.lastName || ''}
            />
          </Box>
        ) : (
          <TextField
            disabled={rowData.hasAuthority || disableEditing || (versionMajor < 1 && versionMinor < 236)}
            fullWidth
            label={t('table.default.detailpane.name')}
            name={'name'}
            onChange={handleRowChange}
            value={rowData.name || ''}
          />
        )}
        <Box display='flex' flexDirection='row' width='100%' gap={2}>
          {getDateTextField(
            beginDate,
            handleChangeBeginDate,
            t('table.default.detailpane.born'),
            rowData.hasAuthority || disableEditing,
            t('table.default.detailpane.yearFormat')
          )}
          {getDateTextField(
            endDate,
            handleChangeEndDate,
            t('table.default.detailpane.dead'),
            rowData.hasAuthority || disableEditing,
            t('table.default.detailpane.yearFormat')
          )}
        </Box>

        <TextField
          disabled={rowData.hasAuthority || disableEditing}
          fullWidth
          label={t('table.default.detailpane.description')}
          multiline
          name={'description'}
          onChange={handleRowChange}
          rows={4}
          value={rowData.description || ''}
        />

        {!disableEditing && (
          <Button
            color='info'
            disabled={rowData.hasAuthority || loading}
            onClick={handleSaveRow}
            sx={{alignSelf:'flex-start'}}
            variant='contained'
          >
            {t('table.default.detailpane.save')}
          </Button>
        )}
      </Box>
      <Box display='flex' flexDirection='column' flex='1'>
        <Box display='flex' marginBottom={0.5} alignItems='center' gap={1} fontSize='1rem'>
          ID:
          {getClickableItem(rowData.id, rowData.objectType, rowData.caption, handleClickField, true,
            t('table.default.detailpane.tooltips.open'))}
        </Box>
        <Box display='flex' marginBottom={0.5} alignItems='center' gap={1} fontSize='1rem'>
          {`${t('table.default.detailpane.collection')}:`}
          {getClickableItem(rowData.collectionId, 'Collection', rowData.collectionIdValue, handleClickField, true,
            t('table.default.detailpane.tooltips.open'))}
        </Box>
        {(rowData.agentTypeIdValue === 'Person' || rowData.agentTypeIdValue === 'Ukjent') && (
          <>
            <Typography>{t('table.default.detailpane.born') + `: ${rowData.beginDate || ''}` }</Typography>
            <Typography>{t('table.default.detailpane.dead') + `: ${rowData.endDate || ''}` }</Typography>
          </>
        )}
        <Typography>{t('table.default.detailpane.information')} </Typography>
        <Typography sx={{fontSize: '14px'}}>
          {rowData.solrData}
        </Typography>
        <DetailPanelUsageList
          usages={usages}
          handleClickField={handleClickField}
          sidePanelOpen={sidePanelOpen}
          setSidePanelOpen={setSidePanelOpen}
          loading={loadingSideActions}
          t={t}
        />
      </Box>
      {!disableEditing && (
        <Box>
          <DetailPanelActionMenu
            row={rowData}
            refreshRow={updateTable}
            dispatchError={dispatchError}
            setUsages={setUsages}
            loading={loadingSideActions}
            setLoading={setLoadingSideActions}
          />
        </Box>
      )}
      <ClickAlert
        open={copyAlertOpen}
        setOpen={setCopyAlertOpen}
        clickCoordinates={clickCoordinates}
        message={t('dialogs.duplicates.idCopied')}
      />
      <SidePanelUsageList
        usages={usages}
        handleClickField={handleClickField}
        open={sidePanelOpen}
        setOpen={setSidePanelOpen}
        t={t}
      />
    </Box>
  )
}
