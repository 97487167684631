import { Divider, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const NoAccessContent = ({ children }) => {
  const { t } = useTranslation('translations');

  const dmUrl = 'https://digitaltmuseum.org/021015683216';
  const imgLabel = t('auth.imgLabel');

  return (
    <>
      <Typography variant='h3' sx={{ paddingBottom: '32px' }}>{t('auth.noAccessHeader')}</Typography>
      {children}
      <Divider width='460px' sx={{ my: '32px' }} />
      <Link href={dmUrl} target="_blank" rel="noopener">
        <img src='/wash.webp' alt={imgLabel}/>
      </Link>
      <Typography variant="subtitle">
        {imgLabel}
      </Typography>
    </>
  )
}