import '@fontsource/roboto';
import { Dehaze, FiberSmartRecord } from '@mui/icons-material';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { MENU_CLOSED, useAppDispatch } from '../../app/AppContext';
import { SET_HELP_MENU_CONTENT_URL, useMainDispatch, VIEW_CHANGED } from '../../contexts/MainContext';
import { usePrimusState } from "../../contexts/PrimusContext";
import { SET_TABLE_PINNED_ROWS, useTableDispatch } from "../../contexts/TableContext";
import { TvattHelpButton } from '../TvattHelpMenu/TvattHelpButton';
import { FilterList } from "./FilterList";

export const TvattMenuOptions = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const navigate = useNavigate();

  const { versionMajor, versionMinor } = usePrimusState();
  const { t } = useTranslation('translations');

  const handleNavigation = (path) => {
    dispatch({ type: MENU_CLOSED });

    tableDispatchCallback({
      type: SET_TABLE_PINNED_ROWS,
      pinnedRows: {
        top: [],
        bottom: []
      }
    });

    mainDispatchCallback({
        type: VIEW_CHANGED
    });

    navigate(path);
  }

  return (
    <>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation('/')}
            selected={location.pathname === '/'}
            sx={{
              padding: 2,
              width: '100%'
            }}
          >
            <ListItemIcon>
              <Dehaze color={location.pathname === '/' ? 'primary' : undefined} />
            </ListItemIcon>
            <ListItemText primary={t('menu.washer.list')} />
          </ListItemButton>
        </ListItem>
        <FilterList />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation('/duplicate')}
            selected={location.pathname === '/duplicate'}
            sx={{
              padding: 2,
              width: '100%'
            }}
          >
            <ListItemIcon>
              <FiberSmartRecord color={location.pathname === '/duplicate' ? 'primary' : undefined} />
            </ListItemIcon>
            <ListItemText primary={t('menu.washer.grouped')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Box sx={{ alignItems: 'flex-end', display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column',  width: '100%' }}>
          <Tooltip title={`Primus: 10.${versionMajor}.${versionMinor}`}>
            <Typography sx={{ color: "#a5a5a5" }} variant='subtitle2' onClick={() => {
              mainDispatchCallback({
                type: SET_HELP_MENU_CONTENT_URL,
                helpMenuContentTitle: t('helpTitles.versionLog'),
                helpMenuContentUrl: t('helpFiles.versionLog')
              })
            }}>
              {t('menu.washer.version')}: {process.env.REACT_APP_VERSION}
            </Typography>
          </Tooltip>
          <TvattHelpButton
            icon
            title={t('helpTitles.help')}
            url={t('helpFiles.mainHelp')}
          />
        </Box>
      </Box>
    </>
  )
}