import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Toolbar } from '@mui/material';
import { PrimusRights } from "./PrimusRights";

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  },
  container: {
    height: (props) => `calc(100% - ${props.toolbarHeight}px)`,
    overflow: 'hidden',
    padding: 0
  }
}));

export const AppContent = ({ children }) => {
  const toolbarRef = useRef();

  const [toolbarHeight, setToolbarHeight] = useState(64);

  const classes = useStyles({
    toolbarHeight: toolbarHeight
  });

  useEffect(() => {
    if (toolbarRef.current) {
      setToolbarHeight(toolbarRef.current.clientHeight);
    }
  }, [setToolbarHeight]);

  return (
    <div className={classes.root}>
      <Toolbar ref={toolbarRef} />
      <Container maxWidth={false} className={classes.container}>
        <PrimusRights>
          {children}
        </PrimusRights>
      </Container>
    </div>
  );
  // return null;
}
