import { isUUID } from 'validator';
import { v4 as uuid } from 'uuid';

import { buildSolrSearchParameters } from "../utils/primusSolrSearchBuilder";
import { primus10ToWasher } from "../utils/primusDataHandling";
import { primus10TermsToWasher } from "../utils/primusTermDataHandling";
import { HttpService } from './HttpService';
import { SolrSearchParams } from '../declarations/SolrSearchParameters';

export class PrimusApi extends HttpService {
     constructor(
        baseUrl = window.localStorage.getItem('tvattmaskin.primus_baseurl')
    ) {
        super();
        this.baseUrl = baseUrl;
    }

    static getToken() {
        return window.localStorage.getItem('kit.access_token');
    }

    static getAuthHeader() {
        const token = PrimusApi.getToken();
        return token ? `Bearer ${token}` : '';
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    getDefaultConfig() {
        return this.combineConfigs(super.getDefaultConfig(), {
            headers: new Headers({
              Authorization: PrimusApi.getAuthHeader(),
              'Correlation-Id': uuid()
            }),
            credentials: 'include',
        });
    }

    getPathPrefix() {
        return '/cms_api/v1.0';
    }

    async checkOk(p) {
        const res = await p;
        if (!res?.ok) {
            return Promise.resolve(p)
        }
        return res;
    }

    async createDescriptionObject() {
       return this.json(
         this.get('/artifact/create/SuperobjectDescription')
       )
    }

    async json(p) {
        const res = await this.checkOk(p);
        return res?.status ? res.json() : res;
    }

    async executeOperationStep(operation) {
         return this.json(
             this.post(
                 '/meta_operation/execute_operation_step',
                 operation
             )
         )
    }

    async executeOperation(operation) {
        return this.json(
            this.post(
                '/meta_operation/execute_operation',
                operation
            )
        )
    }

    async getJobStatus() {
         return this.json(
             this.get('/jobstatus/')
         )
    }

    async getOperations() {
      return this.json(
        this.post(
          '/meta_operation/operations',
          {
            operation_target: 'washing_machine',
            // Hack to get join operation since it has requirement for 2 or more selected contexts
            operation_contexts: [{}, {}],
            meta_types: ['agent', 'concept']
          }
        )
      )
    }

    async getOperationObject(params) {
      return this.json(
        this.post(
          '/meta_operation/operation_object',
          params
        )
      )
    }

    async getSelectedListData(
      indexedData,
      selectedList,
      t,
      duplicateView,
      statusFilter,
      getUsage
    ) {
      let primusData;
      let searchParams;
      let usageData = [];

      if (isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('(')) {
        if (selectedList.startsWith('ct_25')) {
          searchParams = buildSolrSearchParameters(duplicateView ? 'classificationDuplicate' : 'classificationList', selectedList, statusFilter);
        }
        else {
          searchParams = buildSolrSearchParameters(duplicateView ? 'properNameDuplicate' : 'properNameList', selectedList, statusFilter);
        }
      }
      else {
        searchParams = buildSolrSearchParameters(duplicateView ? 'termDuplicate' : 'termList', selectedList, statusFilter);
      }

      primusData = await this.search(searchParams);

      if (getUsage && primusData?.artifacts?.length > 0) {
        let ids = primusData.artifacts.map((artifact) => artifact.id);
        usageData = await this.getUsageQuick(ids);
      }

      if (isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('(')) {
        if (selectedList.startsWith('ct_25')) {
          return primus10TermsToWasher(
            t,
            indexedData.bookmarkedRows,
            indexedData.markedNoDuplicatesRows,
            indexedData.markedOkRows,
            indexedData.matchedRows,
            indexedData.editedRows,
            primusData?.artifacts,
            usageData,
            false,
            duplicateView
          );
        }
        return primus10ToWasher(
          t,
          primusData?.artifacts,
          indexedData.bookmarkedRows,
          indexedData.markedNoDuplicatesRows,
          indexedData.markedOkRows,
          indexedData.matchedRows,
          indexedData.editedRows,
          usageData,
          duplicateView ? primusData?.facets[0].items : null,
          duplicateView
        );
      }
      else {
        return primus10TermsToWasher(
          t,
          indexedData.bookmarkedRows,
          indexedData.markedNoDuplicatesRows,
          indexedData.markedOkRows,
          indexedData.matchedRows,
          indexedData.editedRows,
          primusData?.artifacts,
          usageData,
          false,
          duplicateView
        );
      }
    }

    async getTermChildren(artifactId) {
       const searchParams = new SolrSearchParams();
       searchParams.addToQuery('parent_id', artifactId);
       searchParams.setFields('artifact_id,name.name');
       const primusData = await this.search(searchParams);
       if (primusData?.artifacts?.length) {
         return primusData.artifacts.map((a) => ({
           id: a.artifact_id,
           name: a['name.name'],
         }));
       }
       return [];
    }

    async getTermTypes(objectType, query) {
       let searchParameters = new SolrSearchParams();
       searchParameters.setObjectType(objectType);
       searchParameters.addToQuery('name.name_upper', `*${query.toUpperCase()}*`);
       searchParameters.setQueryOpAnd(true);
       searchParameters.setResultLimit(20);
       return this.search(searchParameters);
    }

    async getArtifact(artifactId) {
       return this.json(
         this.get(
           `/artifact/get/${artifactId}`
         )
       )
    }

    async getUsage(ids) {
         return this.json(
             this.post(
                 '/concept/concept_usage',
                 {
                     artifact_ids: ids,
                     writable_usages_only: 'true'
                 }
             )
         )
    }

    async getUsageQuick(ids) {
       return this.json(
         this.post(
           '/artifact/usage',
           {
             artifact_ids: ids
           }
         )
       )
    }

    async search(params) {
        if (!(params instanceof SolrSearchParams)) {
            throw Error('Searchparameters must be of type SolrSearchParams');
        }

        return this.json(
            this.put(
                `/search/json`,
                params.getSearchBody()
            )
        );
    }

    async putArtifact(body) {
       return this.json(
         this.post(
           '/artifact/put',
           body
         )
       )
    }

    async validateOperation(params) {
         return this.json(
             this.post(
                 '/meta_operation/validate_operation',
                 params
             )
         )
    }

  /**
   * Import agent or concept from KulturNav and save in Primus database.
   * @param uuid - The uuid of the KulturNav object
   * @param concept_type_id - The concept type id if saving as a concept, or 'agent' if saving as agent (egennavn)
   * @returns The created object
   */
    async importFromKulturNav(uuid, concept_type_id) {
        return this.json(
            this.get(`/kulturnav/import_entity/${uuid},${concept_type_id}`)
        )
    }

    async getUser() {
      return this.json(
        this.get('/setting/get_user_config')
      )
    }

    async getCollections(superobject_type_ids = []) {
      let searchParams = new SolrSearchParams();
      searchParams.setObjectType('Collection')
      if (superobject_type_ids.length) {
        searchParams.addToQuery('filters.superobject_type_id', superobject_type_ids.join(','));
      }
      searchParams.setQueryOpAnd(true);
      return this.search(searchParams).then(res => res?.artifacts || []);
    }
}
