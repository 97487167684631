import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NoAccessContent } from "./NoAccessContent";

export const NoAccessView = () => {
  const { t } = useTranslation('translations');

  const supportMail = 'support@kulturit.org';

  return (
    <Box
      padding='80px 16px 16px 16px'
      height='100vh'
      width='100vw'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <NoAccessContent>
        <Typography>{t('auth.noAccessContactSupport')}</Typography>
        <Link href={`mailto:${supportMail}`}>{supportMail}</Link>
      </NoAccessContent>
      <Typography variant='h3' sx={{ paddingBottom: '32px' }}>{t('auth.noAccessHeader')}</Typography>
    </Box>
  )
}